@import 'assets/styles/Global.scss';

body {
    background: var(--color-bg-main);
    /* width */
    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F6F6F6; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: gray;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}
.switch-wrapper {
    position: relative;
}

.highlight {
    &:hover { 
        font-weight: bold;
        color: var(--color-blue) !important;
    }
}
// .switch-wrapper > div {
//     position: absolute;
// }
.info-modal,
.connect-modal,
.collateral-confirm-modal {
    .ant-modal-content {
        border-radius: 6px;
        background-color: var(--color-bg-modal);
        .ant-modal-body {
            padding: 0;
            border-radius: 6px;
        }
    }
}

.info-modal {
    .ant-modal-content {
        max-width: 430px;
        margin: auto;
    }
}

.scrollbar {
    /* width */
    &::-webkit-scrollbar {
        width: 7px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 6px;
        background-color: var(--color-blue);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #e53d52;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

button:disabled,
button[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #D3D3D3 !important;
    background-image: linear-gradient(to right, #D3D3D3, #D3D3D3) !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

button:hover {
    background-color: #539ef9 !important;
}

.ant-select-dropdown-menu-item {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-text-main);

    &:hover {
        background-color: var(--color-blue) !important;
        color: #ffffff;
    }
}

.ant-select-dropdown-menu-item-selected {
    color: #ffffff;
    font-weight: 600;
    background-color: var(--color-blue) !important;
}

input {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        padding-right: 22px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        padding-right: 22px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        padding-right: 22px;
    }
    &:-moz-placeholder { /* Firefox 18- */
        padding-right: 22px;
    }
}
// Common CSS

.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.just-center {
    justify-content: center;
}
.just-between {
    justify-content: space-between;
}
.just-around {
    justify-content: space-around;
}
.just-end {
    justify-content: flex-end;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.left {
    text-align: left;
}
.pointer {
    cursor: pointer;
}
.full {
    width: 100%;
}
.gap-menu {
    gap: 10px;
}

.ant-dropdown-menu {
    background-color: var(--color-bg-primary);
}

.ant-table-placeholder {
    background: var(--color-bg-primary);
}

.ant-pagination-item-link {
    background: var(--color-bg-primary) !important;
    color: var(--color-text-main) !important;
}

.ant-pagination-item {
    background-color: unset;
}
.ant-pagination-item a {
    background: var(--color-bg-primary) !important;
    color: var(--color-text-main) !important;
}

.ant-pagination-item-ellipsis {
    color: var(--color-text-main) !important;
}

.ant-dropdown-menu-item {
    &:hover {
        background-color: transparent;
        a span{        
            color: white !important;
        }
    }
}

.ant-table-placeholder {
    border-bottom: 0px !important;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--color-text-inactive) !important;
}

.ant-dropdown-menu-item > a {
    display: flex !important;

    &:hover {
        svg path {
            fill: #107def;
        }
    }

    .active {
        color: var(--color-white) !important;
        svg path {
            fill: #107def;
        }
    }
}

.ant-dropdown-menu-item {
    .active {
        span {
            color: var(--color-white);
        }
        svg path {
            fill: #107def;
        }
    }
}

button:disabled {
    background: var(--color-text-secondary) !important;
    color: #a5accf !important;
}



.rc-md-editor .editor-container .sec-md .input {
    background: var(--color-bg-main);
    color: var(--color-text-main);
}

.rc-md-editor .editor-container .sec-html .html-wrap {
    background: var(--color-bg-main);
    border-left: 1px solid white;
    .custom-html-style {
        color: var(--color-text-main);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--color-text-main);
    }
}

.rc-md-editor .rc-md-navigation {
    background: var(--color-bg-main);
}

.rc-md-editor .editor-container>.section {
    border: 0px;
}

.rc-md-editor .rc-md-navigation .button-wrap .button i {
    color: var(--color-text-main);
}