:root {
    /* Common styles */
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-orange: #ff4a21;
    --color-yellow: #f8b94b;
    --color-purple: #906DE5;
    --color-red: #f9053e;
    --color-green: #1ba27a;
    --color-blue: #107DEF;
    --color-dark-grey: #666771;
    /* Background colors */
    --color-bg-main: #141518;
    --color-bg-primary: #1e1f25;
    --color-bg-modal: #1d1f25;
    --color-bg-active: #14151880;
    /* Typography colors */
    --color-text-main: white;
    --color-text-secondary: #5D6588;
    --color-text-inactive: #5e5f6a;
    --color-text-green: #97cc60;
    --color-text-blue: #2fe29b;
}

@font-face {
    font-family: "Robot";
    src: url("../font/Roboto/Roboto-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Robot";
    src: url("../font/Roboto//Roboto-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Robot";
    src: url("../font/Roboto/Roboto-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Robot";
    src: url("../font/Roboto/Roboto-Bold.ttf");
    font-weight: 700;
}

body * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Robot";
    font-stretch: normal;
    font-style: normal;
}

a, span, p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
}

.input-error {
    color: var(--color-red);
    font-size: 18px;
}
